<!--会员权益-->
<template>
  <div class="rights">
    <section class="table-search">
      <div class="search-L">
        <el-select v-model="tableParams.levelConfigId" class="mr20" placeholder="请选择会员等级" @change="levelChange">
          <el-option v-for="item in vipLevelList" :key="item.levelConfigId" :label="item.levelName"
            :value="item.levelConfigId">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="openPointRights">积分权益</el-button>
        <el-button type="primary" @click="open">添加权益</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
          cursor: 'move'
        }" row-key="equityId">
      <el-table-column label="权益标题" align="center" prop='equityName'>
      </el-table-column>
      <el-table-column label="展示状态" align="center" prop='display'>
        <template slot-scope="scope">
          <div>
            {{ scope.row.display ? '展示' : '不展示' }}
          </div>
        </template>
      </el-table-column>

      <el-table-column width="300" label="操作" align="center">
        <template slot-scope="scope">
          <div style="display: flex;">
            <Setting :equityId="scope.row.equityId" :levelName="levelName" @refresh="reload" />
            <el-button style="margin-left: 20px;" type="text" size="medium" @click.stop="open(scope.row)">编辑</el-button>
            <el-button style="margin-left: 20px;" type="text" size="medium" @click.stop="goClick(0, scope.row)">{{
          scope.row.display ? '隐藏' : '展示' }}</el-button>
            <el-button style="margin-left: 20px;" type="text" size="medium" @click.stop="goClick(1, scope.row)"
              v-if="scope.row.display">删除</el-button>

          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="infoShow" :title="title" :close-on-click-modal="false" width="50%" center
      @close="reset()">
      <el-form :model="rightsForm" :rules="rules" ref="rightsForm">
        <el-form-item label="权益标题" label-width="120px" prop="equityName">
          <el-input v-model="rightsForm.equityName" placeholder="请输入权益标题"></el-input>
        </el-form-item>

        <el-form-item label="权益图标" label-width="120px" prop="icon">
          <div class="upload-btn" @click="onUpload()">
            <img v-if="rightsForm.icon" :src="rightsForm.iconUrl" class="upload-img" />
            <div v-else>
              <i class="el-icon-plus uploader-icon"></i>
              <div class="tip">上传照片</div>
            </div>
          </div>
          <div style="width: 200%;">上传一张500kb以内.jpg.png格式的图片，建议尺寸32*32</div>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 积分权益弹框 -->
    <el-dialog :visible.sync="pointShow" :title="pointTitle" :close-on-click-modal="false" width="50%" center
      @close="reset()">
      <el-form :model="pointForm" :rules="rules" ref="pointForm" label-position="left" label-width="200px">
        <el-form-item label="基础消费积分比" prop="pointsRatio">
          <el-input v-model="pointForm.pointsRatio" disabled></el-input>
        </el-form-item>

        <el-form-item label="会员消费积分倍数" prop="memberPointsMultiple">
          <el-input type="number" min="0" v-model="pointForm.memberPointsMultiple" maxlength="10" placeholder="请输入积分数"
            @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          <div class="note">相对于基础消费积分比的倍数</div>
        </el-form-item>
        <el-form-item label="会员达标赠送积分数" prop="standardGive">
          <el-input type="number" min="0" v-model="pointForm.standardGive" maxlength="10" placeholder="请输入积分数"
            @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
        </el-form-item>
        <el-form-item label="生日当天消费积分倍数" prop="birthdayPointsMultiple">
          <el-input type="number" min="0" v-model="pointForm.birthdayPointsMultiple" maxlength="10" placeholder="请输入积分数"
            @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          <div class="note">相对于基础消费积分比的倍数</div>
        </el-form-item>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">确定</el-button>
      </div>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>
<script>
import Sortable from 'sortablejs'

import { getLevelConfig, addLevelEquity, getLevelEquityList, getLevelEquityDetail, deleteLevelEquity, updateLevelEquity, hideLevelEquity, sortLevelEquity, getPointsEquity, editLevelConfig } from '@/api/user/level.js'
import { ObjToOpt } from "@/utils/utils";
import { getFileList } from "@/api/common";
import UpLoad from "@/components/upload";
import Setting from '../components/setting.vue';
import "@wangeditor/editor/dist/css/style.css";
export default {
  components: { UpLoad, Setting },
  data() {
    return {
      ObjToOpt,
      vipLevelList: [],
      infoShow: false,
      rightsForm: {
        levelConfigId: '',
        equityName: "",
        icon: "",
        iconUrl: "",
      },
      rules: {
        equityName: [
          { required: true, message: '请输入权益标题', trigger: 'blur' },
        ],
        icon: [
          { required: true, message: '请上传权益图标', trigger: 'blur' },
        ],

      },
      tableData: [],
      tableParams: {
        levelConfigId: ''
      },
      editShow: false,
      editTitle: "",
      levelName: '',
      title: '添加权益',
      pointShow: false,
      pointForm: {
        levelConfigId: '',
        pointsRatio: 10,
        memberPointsMultiple:'',
        standardGive: '',
        birthdayPointsMultiple:''
      },
      pointTitle: ''
    };
  },

  mounted() {
    this.getLevelConfigList()
    this.initSort()
  },

  methods: {
    initSort() {
      const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
      // const sortable = new Sortable(el, options);
      // 根据具体需求配置options配置项
      const sortable = new Sortable(el, {
        // number 定义鼠标选中列表单元可以开始拖动的延迟时间；
        delay: 0,
        onEnd: (evt) => { // 监听拖动结束事件
          // 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
          // 下面将拖拽后的顺序进行修改
          const currRow = this.tableData.splice(evt.oldIndex, 1)[0]
          this.tableData.splice(evt.newIndex, 0, currRow)
          let arr = []
          this.tableData.forEach(item => {
            arr.push(item.equityId)
          })
          this.sortLevelEquity(arr)
        }
      })
    },
    open(row) {
      this.infoShow = true
      if (row.equityId) {
        this.getLevelEquityDetail(row.equityId)
        this.title = '编辑权益'
      } else {
        this.title = '添加权益'
      }
    },
    openPointRights() {
      this.pointShow = true
      this.pointTitle = this.levelName + '-积分权益'
      this.getPointsEquity()
    },
    reset() {
      this.rightsForm = {
        levelConfigId: this.tableParams.levelConfigId,
        equityName: "",
        icon: "",
        iconUrl: "",
      }
      this.pointForm = {
        pointsRatio: 10,
        memberPointsMultiple: '',
        standardGive: '',
        birthdayPointsMultiple: ''
      }
      this.$nextTick(() => {
        this.$refs["rightsForm", 'pointForm'].clearValidate();
      })
    },
    // 获取等级列表
    getLevelConfigList() {
      getLevelConfig({}).then((res) => {
        if (res.isSuccess == "yes") {
          this.vipLevelList = res.data;
          this.tableParams.levelConfigId = this.vipLevelList[0].levelConfigId
          this.pointForm.levelConfigId = this.vipLevelList[0].levelConfigId
          this.getLevelEquityList();
          this.levelName = this.vipLevelList[0].levelName

        }
      });
    },
    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds
      };
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.rightsForm.icon = res.data[0].attId
          this.rightsForm.iconUrl = res.data[0].url
        }
      })
    },
    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.getFileList(fileIds);
    },
    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },
    // 选择等级
    levelChange(e) {
      this.rightsForm.levelConfigId = e
      this.pointForm.levelConfigId = e
      this.levelName = this.vipLevelList.find(item => item.levelConfigId == e).levelName
      this.reload()
    },


    // 查询会员权益列表
    getLevelEquityList() {
      getLevelEquityList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },
    // 权排序
    sortLevelEquity(arr) {
      sortLevelEquity({ equityIds: arr }).then(res => {

      })
    },
    // 【请求】权益详情
    getLevelEquityDetail(equityId) {
      getLevelEquityDetail({ equityId: equityId }).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.rightsForm = res.data;
          }
        }
      });
    },
    // 【监听】表单提交
    onSubmit() {
      this.$refs.rightsForm.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑
          if (this.rightsForm.equityId) {
            updateLevelEquity(this.rightsForm).then((res) => {
              if (res.isSuccess == "yes") {
                this.$refs.up.onClear();
                this.reload();
                this.$message({
                  message: res.message,
                  type: "success",
                });

              }
            });
            // editLevelConfig(this.rightsForm).then(res => {
            //   this.$message.success("修改成功");
            // })
          } else {
            this.rightsForm.levelConfigId = this.tableParams.levelConfigId;
            addLevelEquity(this.rightsForm).then(res => {
              this.$refs.up.onClear();
              this.reload();
            })
          }

          this.infoShow = false;
        } else {
          // 表单验证失败，提示用户
          //this.$message.error("表单验证失败，请检查输入！");
          return false;
        }
      });
    },

    // 【监听】隐藏、展示
    goClick(type, row) {
      let api = [hideLevelEquity, deleteLevelEquity]
      this.$confirm(`是否确定${type == 1 ? '删除该权益？ ' : (row.display ? '隐藏' : '展示') + '该权益？ 确认后小程序会员权益展示处将立即生效'}`, `${type == 1 ? '删除权益' : (row.display ? '隐藏' : '展示') + '状态'}`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        type: "",
      }).then(() => {
        api[type]({ equityId: row.equityId, display: !row.display }).then(res => {
          this.reload();
          this.$message.success(res.message);
          this.reload();
        })
      });
    },
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getLevelEquityList();
    },
    getPointsEquity() {
      let data = {
        levelConfigId: this.tableParams.levelConfigId
      }

      getPointsEquity(data).then(res => {
        this.pointForm = res.data

      })
    },

    // 保存会员积分权益
    save() {
      editLevelConfig(this.pointForm).then(res => {
        this.pointShow = false
        this.reload();
      })
    }

  },
};
</script>
<style lang="scss" scoped>
/deep/.el-table__row {
  &:hover {
    cursor: move;
  }
}

.note {
  color: red;
  font-size: 12px;
}

.rights {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  position: relative;

  .tip {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
  }
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}

.mobile {
  width: 780px;
  height: 580px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #aaa;
}
</style>
